<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/> -->
  <div>
    <h5>Network Monitor by Ligno Software Solution</h5>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount } from "vue";
import axios from "axios";
import moment from "moment";
export default {
  setup() {
    let interval = null;

    onMounted(async () => {
      login();
      interval = setInterval(() => {
        callGoogle();
        callDks();
      }, 10000);
      window.addEventListener("beforeunload", () => {
        logout();
        clearInterval();
      });
    });

    onBeforeUnmount(() => {
      clearInterval();
      window.removeEventListener("beforeunload", clearInterval);
    });

    let clearInterval = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    let login = () => {
      axios.post(`${process.env.VUE_APP_BASE_URL}/logging_in`);
    };

    let logout = () => {
      axios.post(`${process.env.VUE_APP_BASE_URL}/logging_out`);
      clearInterval();
    };

    let callGoogle = async () => {
      let begin = moment();
      let resp = null;

      try {
        resp = await axios.get("https://api.github.com/");
      } catch (err) {
      } finally {
        let end = moment();
        let diff = end.diff(begin);
        let paylaod = {
          server: "GOOGLE",
          report: `Called on ${moment(begin).format(
            "YYYY-MM-DD HH:mm:ss.SSS"
          )} - ${moment(end).format("YYYY-MM-DD HH:mm:ss.SSS")} (${
            diff > 1000 ? `${diff} !warning` : diff
          }) ===RESP=== ${
            typeof resp == "object" ? JSON.stringify(resp) : resp
          }`,
        };
        axios.post(`${process.env.VUE_APP_BASE_URL}/log`, paylaod);
      }
    };

    let callDks = async () => {
      let begin = moment();
      let resp = null;
      try {
        resp = await axios.get(
          "https://drkevin-scan-backend.lignosoftware.com/"
        );
      } catch (err) {
      } finally {
        let end = moment();
        let diff = end.diff(begin);

        let paylaod = {
          server: "DKS",
          report: `Called on ${moment(begin).format(
            "YYYY-MM-DD HH:mm:ss.SSS"
          )} - ${moment(end).format("YYYY-MM-DD HH:mm:ss.SSS")} (${
            diff > 1000 ? `${diff} !warning` : diff
          })  ===RESP=== ${
            typeof resp == "object" ? JSON.stringify(resp) : resp
          }`,
        };
        axios.post(`${process.env.VUE_APP_BASE_URL}/log`, paylaod);
      }
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
